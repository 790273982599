class BlockConfigurator {
    static id = 0;

    constructor(editor, exposables = true, forParent = false) {
        this.editor = editor;
        this.id = `blockConfigurator-${BlockConfigurator.id++}`;
        
        // Which configuration options should be passed up to the parent
        // true = all, false = none, list[keys] = the specific items
        this.exposables = exposables;
        this.block = false;

        this.onApplyCallback = false;
        this.onCancelCallback = false;       
        this.container = false; 

        this.initMethods = [];
    }

    isOptionExposable(option) {
        if (this.exposables === true) {
            return true;
        }
        if (this.exposables === false) {
            return false;
        }
        return option in this.exposables;
    }

    setOnApplyCallback(callback) {
        this.onApplyCallback = callback;
    }

    setOnCancelCallback(callback) {
        this.onCancelCallback = callback;
    }

    getConfiguration() {
        let config = {};

        let options = Object.assign({}, this.editor.buildingBlocks[this.editor.getBlockType(this.block)]["configuration_options"]);
        let parent = this.editor.getParentBlock(this.block);
        let parent_options = {};
        if (parent !== false) {
            parent_options = this.editor.buildingBlocks[this.editor.getBlockType(parent)]["children_configuration_options"];
        }
        for (let child of this.editor.getChildrenBlocks(this.block)) {
            let child_exposed_options = this.editor.getExposedOptions(child);
            for (let key in child_exposed_options) {
                if (!(key in options)) {
                    options[key] = child_exposed_options[key];
                }
            }
        }
        for (let parent_option in parent_options) {
            if (parent_option in options) {
                new Notification().error("Doppelte Konfigurationseigenschaft: " + parent_option);
                continue;
            }
            options[parent_option] = parent_options[parent_option];
        }
        
        for (let option in options) {
            console.log(option);
            let value = undefined;
            let value_type = options[option]["type"];            
            let input_id = `${this.id}-input-${option}`;
            let input = $(`#${input_id}`);
            let is_exposed = this.isOptionExposed(option);            
            let exposed_name = false;
            if (is_exposed) {
                exposed_name = this.getExposedName(option);
            }
            if ("choices" in options[option]) {
                value_type = "select";
            }

            if (value_type === "string" || value_type === "css" || value_type === "text") {
                value = input.val();
            } else if (value_type === "number") {
                value = Number.parseFloat(input.val());
            } else if (value_type === "bool") {                
                value = input.find(":selected").val();
                value = value === "true" ? true : false;
            } else if (value_type === "select") {                
                value = input.find(":selected").val();
            } else if (value_type === "html") {
                value = tinymce.get(input_id).getContent();
            } else if (value_type === "picture") {
                if (options[option]["multiples"] === true) {
                    value = input.val();
                    if (!Array.isArray(value)) {
                        value = JSON.parse(value);
                    }
                    if (value === null) {
                        value = [];
                    }
                } else {
                    value = Number.parseInt(input.val());
                }
            } else if (value_type === "pobject") {
                if (options[option]["multiples"] === true) {
                    value = input.val();
                    if (!Array.isArray(value)) {
                        value = JSON.parse(value);
                    }
                    if (value === null) {
                        value = [];
                    }
                } else {
                    value = input.val();
                }
            } else {
                new Notification().error(`Unknown field ${option} - ${value_type}`);
            }
            
            config[option] = {
                "is_exposed": is_exposed,
                "exposed_name": exposed_name,
                "value": value
            };
        }    
        return config;
    }

    show(container, block) {
        let current_config = this.editor.getData(block);
        let that = this;
        let inputs = [];
        this.initMethods = [];
        this.container = container;
        this.block = block;

        let block_type = this.editor.getBlockType(block);
        let buildingBlock = this.editor.buildingBlocks[block_type];
        let options = buildingBlock["configuration_options"];

        let exposedOptions = {};
        for (let child of this.editor.getChildrenBlocks(block)) {
            let childExposedOptions = this.editor.getExposedOptions(child);
            Object.assign(exposedOptions, childExposedOptions);
        }
        for (let key in exposedOptions) {
            if (key in options) {
                delete exposedOptions[key];
            }
        }
        let parent = this.editor.getParentBlock(block);
        let parent_options = {}
        if (parent !== false) {
            parent_options = Object.assign({}, this.editor.buildingBlocks[this.editor.getBlockType(parent)]["children_configuration_options"]);
            for (let parent_option in parent_options) {
                if (parent_option in options) {
                    new Notification().error("Doppelte Konfigurationsoption: " + parent_option);
                    delete parent_options[parent_option];
                }
            }
        }

        for (let option in options) {
            let option_spec = options[option];
            this.pushInputField(inputs, option, option_spec, current_config);
        }
        if (Object.keys(exposedOptions).length > 0) {
            inputs.push(`<h4>Vererbte Konfigurationsoptionen</h4>`);
            for (let exposedOption in exposedOptions) {
                let option_spec = exposedOptions[exposedOption];
                this.pushInputField(inputs, exposedOption, option_spec, current_config);
            }
        }
        if (Object.keys(parent_options).length > 0) {
            inputs.push(`<h4>Konfigurationsoptionen des Elternblocks</h4>`);
            for (let parent_option in parent_options) {
                let option_spec = parent_options[parent_option];
                this.pushInputField(inputs, parent_option, option_spec, current_config);
            }
        }

        $(container).html(`
            <div class="block_configurator" id="${this.id}">
                <div class="input"></div>
                <div class="actions right-align">
                    <div class="btn waves-effect red darken-4 block-configurator-cancel">Abbrechen</div>
                    <div class="btn waves-effect blue darken-2 block-configurator-apply">Speichern</div>
                </div>
            </div>
        `);

        $(container).on("click", ".block-configurator-cancel", function() {
            if (that.onCancelCallback) {
                that.onCancelCallback();
            }
        });

        $(container).on("click", ".block-configurator-apply", function() {
            if (that.onApplyCallback) {
                that.onApplyCallback(that.getConfiguration());
            }
        });

        let wrapper = $(container).children(".block_configurator").children(".input");
        for (let input of inputs) {
            let input_element = $(input);
            wrapper.append(input_element);
            if (input_element.prop("tagName") !== "DIV") {
                continue;
            }
            let option_name = input_element.data("name");
            if (this.isOptionExposable(option_name)) {
                let is_exposed = this.isPreviouslyOptionExposed(option_name, current_config, options, parent_options);
                let checked = is_exposed ? "checked" : "";
                input_element.find(".input-label-area").append(`
                    <div class="switch">
                        <label>
                        Konfigurieren
                        <input class="input-expose-switch" data-name="${option_name}" type="checkbox" ${checked}>
                        <span class="lever"></span>
                        Vererbt
                        </label>
                    </div>
                `);
                input_element.append(`
                    <div class="input-expose-area">
                        <label>Name für die Konfigurationsvererbung</label>
                        <input type="text" placeholder="Erbname" name="expose-name-${option_name}" value="${option_name}" />
                    </div>
                `);
                this.applyExposedState(option_name, is_exposed);
            }
        }

        $(container).off("change", ".input-expose-switch");
        $(container).on("change", ".input-expose-switch", function() {
            let option = $(this).data("name");
            let is_exposed = $(this).is(":checked");
            that.applyExposedState(option, is_exposed); 
        })
        for (let method of this.initMethods) {
            method();
        }
        if (inputs.length === 0) {
            $(container).find(".block-configurator-apply").trigger("click");
        }
    }

    pushInputField(inputs, option, option_spec, current_config) {
        let option_value = "";
        if (option in current_config) {
            option_value = current_config[option]["value"];
        } else if ("default" in option_spec) {
            option_value = option_spec["default"];
        }
        let title = option;
        let description = false;
        if ("title" in option_spec) {
            title = option_spec["title"];
        }
        if ("description" in option_spec) {
            description = option_spec["description"];
        }
        let option_type = option_spec["type"];

        let choices = false;
        if ("choices" in option_spec) {
            choices = option_spec["choices"];
            option_type = "select";
        }

        switch (option_type) {
            default:
            case "string":
                inputs.push(this.getStringInput(option, title, description, option_value));
                break;
            case "text":
                inputs.push(this.getTextInput(option, title, description, option_value));
                break;
            case "html":
                inputs.push(this.getHtmlInput(option, title, description, option_value));
                break;
            case "number":
                inputs.push(this.getNumberInput(option, title, description, option_value));
                break;
            case "select":
                inputs.push(this.getSelectInput(option, title, description, option_value, choices));
                break;
            case "bool":
                inputs.push(this.getBoolInput(option, title, description, option_value));
                break;
            case "picture":
                if (option_spec["multiples"] === true) {
                    inputs.push(this.getPictureMultiInput(option, title, description, option_value));
                } else {
                    inputs.push(this.getPictureInput(option, title, description, option_value));
                }
                break;
            case "pobject":
                let object_class = option_spec["class"];
                let object_name = object_class.split("\\").pop();
                let selectCount = 1
                if (option_spec["multiples"] === true) {
                    selectCount = 2;
                }
                inputs.push(this.getPObjectInput(option, title, description, option_value, object_class, object_name, selectCount, option_spec));
                break;
        }
    }

    getExposedName(option) {
        let input_element = $(this.container).find(".input").children("*[data-name='"+option+"']");
        let exposed_name = input_element.find(".input-expose-area").find("input").val();
        return exposed_name;
    }    

    isOptionExposed(option) {
        let input_element = $(this.container).find(".input").children("*[data-name='"+option+"']");
        let switch_element = input_element.find(".input-expose-switch");
        if (switch_element.length === 0) {
            return false;
        }
        return switch_element.is(":checked");
    }

    isPreviouslyOptionExposed(option, current_config, own_config, parent_config) {
        if (option in current_config) {
            return current_config[option]["is_exposed"];
        }
        if (option in own_config) {
            return false;
        }
        if (option in parent_config) {
            return false;
        }
        return true;
    }

    applyExposedState(option, is_exposed) {
        let input_element = $(this.container).find(".input").children("*[data-name='"+option+"']");
        if (is_exposed) {
            input_element.find(".input-input-area").hide();
            input_element.find(".input-expose-area").show();
        } else {
            input_element.find(".input-expose-area").hide();
            input_element.find(".input-input-area").show();
        }
    }

    getStringInput(name, title, description, value) {
        if (!description) {
            description = "";
        }
        return `
            <div class="browser-default" data-name="${name}">
                <div class="input-label-area">
                    <label for="${this.id}-input-${name}">${title}</label>
                </div>
                <span class="description">${description}</span>
                <div class="input-input-area">
                    <input placeholder="${description}" id="${this.id}-input-${name}" type="text" value="${value}" />
                </div>
            </div>
            `;
    }

    getTextInput(name, title, description, value) {
        if (!description) {
            description = "";
        }
        return `
            <div class="browser-default" data-name="${name}">
                <div class="input-label-area">
                    <label for="${this.id}-input-${name}">${title}</label>
                </div>
                <span class="description">${description}</span>
                <div class="input-input-area">
                    <textarea placeholder="${description}" id="${this.id}-input-${name}">${value}</textarea>
                </div>
            </div>
            `;
    }

    getNumberInput(name, title, description, value) {
        if (!description) {
            description = "";
        }
        return `
            <div class="browser-default" data-name="${name}">
                <div class="input-label-area">
                    <label for="${this.id}-input-${name}">${title}</label>
                </div>
                <span class="description">${description}</span>
                <div class="input-input-area">
                    <input placeholder="${description}" id="${this.id}-input-${name}" type="number" value="${value}" />
                </div>
            </div>
            `;
    }

    getBoolInput(name, title, description, value) {
        return this.getSelectInput(name, title, description, value, {"Ja": true, "Nein": false});
    }

    getHtmlInput(name, title, description, value) {
        if (!description) {
            description = "";
        }
        let that = this;
        this.initMethods.push(function() {
            initEditor(`#${that.id}-input-${name}`);
        });
        return `
            <div class="browser-default" data-name="${name}">
                <div class="input-label-area">
                    <label for="${this.id}-input-${name}">${title}</label>
                </div>
                <span class="description">${description}</span>
                <div class="input-input-area">
                    <textarea id="${this.id}-input-${name}" class="ck" name="${name}">${value}</textarea>
                </div>
            </div>`;
    }

    getSelectInput(name, title, description, value, choices) {
        if (!description) {
            description = "";
        }

        if (Array.isArray(choices)) {
            let t_choices = {}
            for (let choice of choices) {
                t_choices[`${choice}`] = choice;
            }
            choices = t_choices;
        }

        let options = "";
        for (let choice in choices) {
            let choice_value = choices[choice];
            let selected = value === choice_value ? "selected" : "";
            options += `
            <option value="${choice_value}" ${selected}>${choice}</option>`;
        }

        return `
            <div class="browser-default" data-name="${name}">
                <div class="input-label-area">
                    <label for="${this.id}-input-${name}">${title}</label>
                </div>
                <span class="description">${description}</span>
                <div class="input-input-area">
                    <select id="${this.id}-input-${name}" class="browser-default">
                        ${options}
                    </select>
                </div>
            </div>
            `;
    }

    getPictureInput(name, title, description, value) {        
        let that = this;
        this.initMethods.push(function() {
            initAdminPictureSelect($(`#${that.id}-pictureselect-${name}`));
        });
        return `
            <div data-name="${name}">                
                <div class="input-label-area">
                    <label for="${this.id}-input-${name}">${title}</label>
                </div>
                <span class="description">${description}</span>
                <div class="input-input-area">
                    <div class="adminpictureselect" id="${this.id}-pictureselect-${name}"
                        data-title="" 
                        data-name="${name}" 
                        data-input="${this.id}-input-${name}" 
                        data-value="${value}">
                    </div>
                </div>
            </div>`;
    }

    getPictureMultiInput(name, title, description, value) {        
        let that = this;
        this.initMethods.push(function() {
            initAdminPictureMultiSelect($(`#${that.id}-picture-multi-select-${name}`));
        });
        let pictures = JSON.stringify(value);
        return `
            <div data-name="${name}">                
                <div class="input-label-area">
                    <label for="${this.id}-input-${name}">${title}</label>
                </div>
                <span class="description">${description}</span>
                <div class="input-input-area">
                    <div class="adminpicturemultiselect" id="${this.id}-picture-multi-select-${name}"
                        data-title="" 
                        data-name="${name}" 
                        data-input="${this.id}-input-${name}" 
                        data-value='${pictures}'>
                    </div>
                </div>
            </div>`;
    }

    getPObjectInput(name, title, description, value, object_class, object_name, selectCount, option_spec) {        
        let that = this;
        let objectFilter = option_spec.objectfilter ?? "";
        let objectLabler = option_spec.objectlabler ?? "";
        let objectSorter = option_spec.objectsorter ?? "";
        if (objectFilter !== "") {
            objectFilter = `data-objectfilter="${objectFilter}"`;
        }
        if (objectLabler !== "") {
            objectLabler = `data-objectlabler="${objectLabler}"`;
        }
        if (objectSorter !== "") {
            objectSorter = `data-objectsorter="${objectSorter}"`;
        }
        
        this.initMethods.push(function() {
            initAdminPObjectSelect($(`#${that.id}-pobjectselect-${name}`));
        });
        return `
            <div data-name="${name}">                
                <div class="input-label-area">
                    <label for="${this.id}-input-${name}">${title}</label>
                </div>
                <span class="description">${description}</span>
                <div class="input-input-area">
                    <div class="adminpobjectselect" id="${this.id}-pobjectselect-${name}"
                        data-title="" 
                        data-selectcount="${selectCount}"
                        data-objectName="${object_name}"
                        data-name="${name}" 
                        data-input="${this.id}-input-${name}" 
                        data-value="${value}"
                        data-objectClass="${object_class}"
                        ${objectFilter}
                        ${objectLabler}
                        ${objectSorter}
                        >
                    </div>
                </div>
            </div>`;
    }
}
